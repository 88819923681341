<template>
  <BasePage :loading="loading">
    <Container>
      <div>
        <v-row v-if="sections && !loading">
          <v-col cols="12" md="4" order="2" order-md="1">
            <div>
              <v-expansion-panels accordion focusable>
                <v-expansion-panel
                  v-for="(section, idx) in sections"
                  :key="section.id"
                >
                  <v-expansion-panel-header>
                    <div class="d-flex flex-column justify-center ">
                      <!-- 'القسم ' +
                            sectionNumber(idx + 1) +
                            ': ' + -->
                      <div>
                        {{ section[lang + 'title'] }}
                      </div>
                      <div class="caption">{{ section.duration }}</div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="expanel">
                    <v-list nav dense>
                      <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item
                          v-for="(lecture, index) in section.lecture_id"
                          :key="lecture.id"
                          :class="
                            selectedLecture == index &&
                              'v-list-item--active v-item--active'
                          "
                          @click="setLecture(idx, index)"
                        >
                          <v-list-item-icon>
                            <v-icon v-text="'mdi-radiobox-marked'"></v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ lecture[lang + 'title'] }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="grey--text"
                              ><v-icon small class="ml-1"
                                >mdi-play-circle</v-icon
                              >{{ lecture.duration }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-col>
          <v-col cols="12" md="8" order="1" order-md="2">
            <div>
              <v-sheet>
                <div v-if="sections[selectedSection].lecture_id.length">
                  <iframe
                    v-if="
                      sections[selectedSection].lecture_id[selectedLecture]
                        .video_link
                    "
                    :src="
                      sections[selectedSection].lecture_id[selectedLecture]
                        .video_link
                    "
                    width="100%"
                    height="600px"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>

                  <template
                    v-if="
                      sections[selectedSection].lecture_id[selectedLecture]
                        .type === 'challenge'
                    "
                  >
                    <v-carousel v-model="model">
                      <v-carousel-item
                        v-for="question in sections[selectedSection].lecture_id[
                          selectedLecture
                        ].questions"
                        :key="question.id"
                      >
                        <v-sheet color="transparent" height="100%" tile>
                          <v-row
                            class="fill-height pa-10"
                            align="center"
                            justify="center"
                          >
                            <div class="body-1 primary--text">
                              {{ question.question[lang + 'title'] }}
                            </div>
                            <v-row
                              class="d-flex justify-space-around fill-width"
                            >
                              <div
                                v-for="answer in question.answers"
                                :key="answer.id"
                                class="body-2 pa-3 text-center answer-item primary--text"
                                @click="
                                  setAnswer(
                                    currentQuestionIndex,
                                    answer,
                                    question.answers
                                  )
                                "
                              >
                                {{ answer[lang + 'title'] }}
                              </div>
                            </v-row>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </template>
                  <div>
                    <v-divider class="my-10 mx-6" />
                    <div
                      class="body-2 pa-4"
                      v-html="
                        sections[selectedSection].lecture_id[selectedLecture][
                          lang + 'discription'
                        ]
                      "
                    />
                  </div>
                </div>
                <!-- <CourseDesc /> -->
              </v-sheet>
            </div>
          </v-col>
        </v-row>
      </div>
      <Dialog v-if="showDialog" :dialog="showDialog" @closeDialog="closeDialog">
        <div class="d-flex flex-column justify-center">
          <v-icon
            style="fontSize:80px"
            :color="selectedAnswer.id == correctAnswer.id ? 'success' : 'error'"
          >
            {{
              selectedAnswer.id == correctAnswer.id
                ? 'mdi-checkbox-marked-circle'
                : 'mdi-close-circle'
            }}
          </v-icon>
          <!-- <div class="heading">Why Is Answer</div> -->
          <div class="grey lighten-3 mt-3 pa-4" style="border: 1px solid #eee">
            <div>{{ correctAnswer[lang + 'why_is_answer'] }}</div>
          </div>
        </div>
      </Dialog>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
  props: ['course_id'],
  components: {
    Dialog: () => import('@/components/Dialog'),
  },
  data() {
    return {
      selectedItem: 0,
      selectedSection: 0,
      selectedLecture: 0,
      loading: false,
      sections: null,
      challenges: null,
      // test
      answers: [],
      currentQuestionIndex: 0,
      showDialog: false,
      correctAnswer: null,
      selectedAnswer: null,

      // challenge carousel
      model: 0,
      lang: this.$root.lang == 'en' ? 'en' : '',
    };
  },
  methods: {
    async getCourseSections() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/sections?fields=*,lecture_id.*,lecture_id.challenge_id.*&filter[course_id]=${this.course_id}`
        );
        let arr = [];
        let ans;
        let obj = {};
        data.data.forEach((section) => {
          section.lecture_id.forEach((lecture) => {
            arr = [];
            lecture.challenge_id
              .filter((q) => !q.parent_id)
              .forEach((question) => {
                obj = {};
                ans = lecture.challenge_id.filter((x) => {
                  return question.id == x.parent_id;
                });
                // console.log(data.data[index].lecture_id[idx]);
                obj['question'] = question;
                obj['answers'] = ans.sort((a, b) => a.order - b.order);
                // data.data[index].lecture_id[idx]['q'].push('123');
                console.log(obj);
                arr.push(obj);
              });
            lecture['questions'] = arr.sort(
              (a, b) => a.question.order - b.question.order
            );
          });
        });

        this.sections = data.data;
        console.log(data.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    checkAnswer(ans) {
      console.log(ans);
    },
    setLecture(sec, lec) {
      this.selectedSection = sec;
      this.selectedLecture = lec;
      console.log({ sec, lec });
    },
    setAnswer(idx, ans, allAnswers) {
      if (this.answers.length - 1 >= idx) {
        this.answers = [
          ...this.answers.slice(0, idx),
          (this.answers[idx] = ans),
          ...this.answers.slice(idx + 1, this.answers.length),
        ];
      } else {
        this.answers.push(ans);
      }
      this.correctAnswer = allAnswers.find((ans) => ans.is_answer);
      this.selectedAnswer = ans;
      this.showDialog = true;
      // if (this.answers.length >= this.questions.length) {

      // }
      console.log('ans is ', this.answers);
    },
    // sectionNumber(num) {
    //   if (num == 1) return 'الأول';
    //   else if (num == 2) return 'الثانى';
    //   else if (num == 3) return 'الثالث';
    //   else if (num == 4) return 'الرابع';
    //   else if (num == 5) return 'الخامس';
    //   else if (num == 6) return 'السادس';
    //   else if (num == 7) return 'السابع';
    //   else if (num == 8) return 'الثامن';
    //   else if (num == 9) return 'التاسع';
    //   else if (num == 10) return 'العاشر';
    // },
    nextQuestion() {
      this.readyToAnswer = false;
      ++this.currentQuestionIndex;
    },
    prevQuestion() {
      this.readyToAnswer = false;
      --this.currentQuestionIndex;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },

  watch: {},
  computed: {},
  mounted() {
    this.getCourseSections();
    // document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

/* dadbca */
.answer-item {
  border: 1px solid #1d8ac3;
  border-radius: 18px;
  cursor: pointer;
  min-width: 130px;
  transition: all 0.3s ease-in-out;
}

.correct-answer {
  border: 1px solid green;
}

.wrong-answer {
  border: 1px solid red;
}

.answer-item:hover {
  background: #fff;
  transform: scale(1.1);
}

.v-carousel__controls__item span .mdi-circle::before {
  color: #1d8ac3;
}
</style>
