var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePage',{attrs:{"loading":_vm.loading}},[_c('Container',[_c('div',[(_vm.sections && !_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","order":"2","order-md":"1"}},[_c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","focusable":""}},_vm._l((_vm.sections),function(section,idx){return _c('v-expansion-panel',{key:section.id},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex flex-column justify-center "},[_c('div',[_vm._v(" "+_vm._s(section[_vm.lang + 'title'])+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(section.duration))])])]),_c('v-expansion-panel-content',{staticClass:"expanel"},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((section.lecture_id),function(lecture,index){return _c('v-list-item',{key:lecture.id,class:_vm.selectedLecture == index &&
                            'v-list-item--active v-item--active',on:{"click":function($event){return _vm.setLecture(idx, index)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-radiobox-marked')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(lecture[_vm.lang + 'title'])+" ")]),_c('v-list-item-subtitle',{staticClass:"grey--text"},[_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-play-circle")]),_vm._v(_vm._s(lecture.duration))],1)],1)],1)}),1)],1)],1)],1)}),1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"8","order":"1","order-md":"2"}},[_c('div',[_c('v-sheet',[(_vm.sections[_vm.selectedSection].lecture_id.length)?_c('div',[(
                    _vm.sections[_vm.selectedSection].lecture_id[_vm.selectedLecture]
                      .video_link
                  )?_c('iframe',{attrs:{"src":_vm.sections[_vm.selectedSection].lecture_id[_vm.selectedLecture]
                      .video_link,"width":"100%","height":"600px","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":""}}):_vm._e(),(
                    _vm.sections[_vm.selectedSection].lecture_id[_vm.selectedLecture]
                      .type === 'challenge'
                  )?[_c('v-carousel',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.sections[_vm.selectedSection].lecture_id[
                        _vm.selectedLecture
                      ].questions),function(question){return _c('v-carousel-item',{key:question.id},[_c('v-sheet',{attrs:{"color":"transparent","height":"100%","tile":""}},[_c('v-row',{staticClass:"fill-height pa-10",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"body-1 primary--text"},[_vm._v(" "+_vm._s(question.question[_vm.lang + 'title'])+" ")]),_c('v-row',{staticClass:"d-flex justify-space-around fill-width"},_vm._l((question.answers),function(answer){return _c('div',{key:answer.id,staticClass:"body-2 pa-3 text-center answer-item primary--text",on:{"click":function($event){return _vm.setAnswer(
                                  _vm.currentQuestionIndex,
                                  answer,
                                  question.answers
                                )}}},[_vm._v(" "+_vm._s(answer[_vm.lang + 'title'])+" ")])}),0)],1)],1)],1)}),1)]:_vm._e(),_c('div',[_c('v-divider',{staticClass:"my-10 mx-6"}),_c('div',{staticClass:"body-2 pa-4",domProps:{"innerHTML":_vm._s(
                      _vm.sections[_vm.selectedSection].lecture_id[_vm.selectedLecture][
                        _vm.lang + 'discription'
                      ]
                    )}})],1)],2):_vm._e()])],1)])],1):_vm._e()],1),(_vm.showDialog)?_c('Dialog',{attrs:{"dialog":_vm.showDialog},on:{"closeDialog":_vm.closeDialog}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('v-icon',{staticStyle:{"fontSize":"80px"},attrs:{"color":_vm.selectedAnswer.id == _vm.correctAnswer.id ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.selectedAnswer.id == _vm.correctAnswer.id ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle')+" ")]),_c('div',{staticClass:"grey lighten-3 mt-3 pa-4",staticStyle:{"border":"1px solid #eee"}},[_c('div',[_vm._v(_vm._s(_vm.correctAnswer[_vm.lang + 'why_is_answer']))])])],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }